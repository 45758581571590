import css from './ValuableServices.module.scss';
import Container from '../../container/Container';
import classNames from 'classnames';
import SimpleButton from '../../buttons/SimpleButton';
import StatisticItem from './statisticItem/StatisticItem';
import WhiteSection from '../../sections/whiteSection/WhiteSection';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export default function ValuableServices() {
  const {t} = useTranslation();
  const ref = useRef();


  return (
    <WhiteSection>
      <Container>
        <div ref={ref} className={classNames(css.layoutFlex, css.valuePageServices)}>
          <ShowAnimation ref={ref} direction={'hl'} order={1}>
            <div className={css.valueSectionGradientBlock}>
              <div className={css.valueRightTitleContentInner}>
                <img loading={'lazy'} alt={'logo'} src={`/GLAD_${i18n.language}.svg`} style={{width: '100%'}}/>
                {/*<H2title isWhite={true}>{t('our-values.services.title')}</H2title>*/}
                <p className={classNames(css.p, css.white)}>
                  {t('our-values.services.description')}
                </p>
                <div className={css.mt35}>
                  <SimpleButton isSecondary={true} url={'/#/contact-us'} text={t('buttons.get-started')}/>
                </div>
              </div>
              <div className={css.valueRightSectionInnerGradient}/>
            </div>
          </ShowAnimation>

          <div className={css.valuesSectionRightList}>
            <ShowAnimation ref={ref} direction={'hr'} order={1}>
              <StatisticItem title={t('our-values.services.item_1.title')}
                             description={t('our-values.services.item_1.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={2}>
              <StatisticItem title={t('our-values.services.item_2.title')}
                             description={t('our-values.services.item_2.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={3}>
              <StatisticItem title={t('our-values.services.item_3.title')}
                             description={t('our-values.services.item_3.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={4}>
              <StatisticItem title={t('our-values.services.item_4.title')}
                             description={t('our-values.services.item_4.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={5}>
              <StatisticItem title={t('our-values.services.item_5.title')}
                             description={t('our-values.services.item_5.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={6}>
              <StatisticItem title={t('our-values.services.item_6.title')}
                             description={t('our-values.services.item_6.description')}/>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'hr'} order={7}>
              <StatisticItem title={t('our-values.services.item_7.title')}
                             description={t('our-values.services.item_7.description')}/>
            </ShowAnimation>
          </div>
        </div>
      </Container>
    </WhiteSection>
  );
}