import css from './HLItem.module.scss';
import H3title from '../titles/H3title';
import H6title from '../titles/H6title';
import TextGradient from '../titles/TextGradient';
import ButtonsWrapper from '../titles/wrapper/ButtonsWrapper';
import SimpleButton from '../buttons/SimpleButton';
import {useTranslation} from 'react-i18next';

export default function HLItem({isCustom, currencyChar, title, from, price, frequency, isHL, features}) {
  const {t} = useTranslation();

  return (
    <>
      <div className={css.pricingPlanItem}>
        <H3title cn={css.pricingPlanItemTitle}>
          {
            isHL
              ? <TextGradient>{title}</TextGradient>
              : title
          }
        </H3title>
        <div className={css.pricingPlanPriceWrapper}>
          {
            isCustom
              ? <H6title>{price}</H6title>
              : <>
                <span className={css.mtAuto}>{from}</span>
                <div className={css.pricingPlanCurrencyIcon}>{currencyChar}</div>
                <H6title cn={css.noMB}>
                  <span className={css.pricingPlanPrice}>{price}</span>
                  /{frequency}
                </H6title>
              </>
          }
        </div>
        <ul className={css.pricingPlanList}>
          {
            features.map(f => {
              const hl = '[HL]';
              if (f.indexOf(hl) > -1) {
                return <li key={`key_${f}`} className={css.pricingPlanListItem}>
                  <TextGradient>{f.replaceAll(hl, '')}</TextGradient>
                </li>
              }
              return <li key={`key_${f}`} className={css.pricingPlanListItem}>{f}</li>
            })
          }
        </ul>
        <div className={css.mt40}>
          <ButtonsWrapper>
            <SimpleButton isFullWidth={true} text={t('buttons.contact-now')} url={'/#/contact-us'}/>
          </ButtonsWrapper>
        </div>
      </div>
    </>
  );
}