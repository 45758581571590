import css from './DetailedWrapper.module.scss';
import classNames from 'classnames';
import MenuPlans from './MenuPlans';

export default function DetailedWrapper() {
  return (
    <>
      <div className={classNames(css.mt40, css.wTabs)}>
        <MenuPlans />
      </div>
    </>
  );
}