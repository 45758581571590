import ScrollToTop from '../helpers/ScrollTop';
import Header from '../header/Header';
import BannerTopSection from '../bannerTopSection/BannerTopSection';
import Container from '../container/Container';
import TitleWrapper from '../titles/wrapper/TitleWrapper';
import ShowAnimation from '../helpers/ShowAnimation';
import H1title from '../titles/H1title';
import Description from '../description/Description';
import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import Footer from '../banners/footer/Footer';
import GetStarted from '../banners/getStarted/GetStarted';
import QuickContactList from '../prices/QuickContactList';
import WhiteSection from '../sections/whiteSection/WhiteSection';
import TextGradient from '../titles/TextGradient';
import HLList from '../prices/HLList';
import H3title from '../titles/H3title';
import DetailedWrapper from '../prices/menu/DetailedWrapper';

export default function Prices() {
  const {t} = useTranslation();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  return (
    <>
      <ScrollToTop/>
      <Header/>
      <BannerTopSection>
        <Container>
          <TitleWrapper ref={ref1}>
            <ShowAnimation ref={ref1} order={1} direction={'v'}>
              <H1title isWhite={true}>{t('prices.title')}</H1title>
            </ShowAnimation>
            <ShowAnimation ref={ref1} order={2} direction={'v'}>
              <Description>
                {t('prices.description')}
              </Description>
            </ShowAnimation>
          </TitleWrapper>
        </Container>
      </BannerTopSection>
      <QuickContactList/>
      <WhiteSection>
        <Container>
          <TitleWrapper ref={ref2}>
            <ShowAnimation ref={ref2} order={1} direction={'v'}>
              <H1title>{t('prices.plans.title')}<br/><TextGradient>{t('prices.plans.title_HL')}</TextGradient></H1title>
            </ShowAnimation>
            <ShowAnimation ref={ref2} order={2} direction={'v'}>
              <Description isDark={true}>
                {t('prices.plans.description')}
              </Description>
            </ShowAnimation>
          </TitleWrapper>
          <div ref={ref4}>
            <ShowAnimation ref={ref4} order={2} direction={'v'}>
              <HLList/>
            </ShowAnimation>
          </div>
          <TitleWrapper ref={ref3}>
            <ShowAnimation ref={ref3} order={1} direction={'v'}>
              <H3title>{t('prices.plans.detailed.title')}</H3title>
            </ShowAnimation>
          </TitleWrapper>
          <ShowAnimation ref={ref3} order={2} direction={'v'}>
            <DetailedWrapper/>
          </ShowAnimation>
        </Container>
      </WhiteSection>
      <GetStarted/>
      <Footer/>
    </>
  )
};