const en = {
  'header': {
    'main': 'Main',
    'our-values': 'Our Values',
    'solutions': 'Solutions',
    'prices': 'Services'
  },
  'buttons': {
    'contact-now': 'Contact Now',
    'get-started': 'Get Started',
    'our-solutions': 'Our Solutions',
    'prices': 'Services',
    'submit': 'Submit'
  },
  'footer': {
    'menu_header': 'Navigation',
    'description': 'Empowering your business through innovative solutions and expert guidance.'
  },
  'home': {
    'top-title-1': 'Empower Your Business ',
    'top-title-2': 'with Solutions Architecture Consulting.',
    'top-description': 'Embark on a transformative journey to unlock the full potential of your IT infrastructure and drive innovation that propels your business forward.',
    'main-focus': {
      'title': 'Innovative Solutions',
      'description': 'Our consulting services are designed to address three critical areas crucial for driving business success.',
      'focus_1': {
        'title': 'High-Performance System Architecture Solutions',
        'link-text': 'Explore high-performance systems',
        'description': 'Utilize expertise in designing and implementing high-load systems to optimize performance and reliability. Deliver solutions for enhanced efficiency and scalability, ensuring seamless operation under heavy loads.'
      },
      'focus_2': {
        'title': 'Regulatory Compliance and Governance',
        'link-text': 'Explore strategies',
        'description': 'Provide comprehensive consulting to navigate regulatory complexities. Develop robust frameworks and strategies to meet industry standards and government regulations, minimizing risks and protecting sensitive data.'
      },
      'focus_3': {
        'title': 'Cutting-Edge Development Practices',
        'link-text': 'Learn more',
        'description': 'Guide clients in adopting modern methodologies and tools to accelerate the software development lifecycle. Implement Agile and DevOps practices to improve collaboration and deliver high-quality solutions efficiently.'
      }
    },
    'inner-block': {
      'title_1': 'Empowering Scalable Solutions ',
      'title_2': 'Innovating New Architectures.',
      'description': 'Your success shapes our collective future, as we pioneer a better world for all.'
    },
    'why-trust': {
      'title_1': 'Why companies large and ',
      'title_2': 'small trust us?',
      'why_1': {
        'title': 'ISO',
        'description': 'ISO-27001 enterprise-grade security compliant'
      },
      'why_2': {
        'title': 'TOGAF',
        'description': 'Industry-leading best practices in architectural frameworks'
      },
      'why_3': {
        'title': '99%',
        'description': 'Success rate or effectiveness'
      },
      'why_4': {
        'title': '1000+',
        'description': 'Community- and expert-built templates'
      },
      'why_5': {
        'title': '13+',
        'description': 'Years of experience in leading and managing teams worldwide'
      },
      'why_6': {
        'title': 'Peer',
        'description': 'Expertise in diverse full-stack technical environments'
      }
    },
    'services': {
      'title_1': 'Elevate Your Business: ',
      'title_2': 'Leading Consulting Services',
      'description_1': 'Our expert consulting services offer comprehensive solutions tailored to meet the unique needs of businesses in the Solutions Architecture domain. From architectural design and optimization to regulatory compliance and governance, we provide strategic guidance and innovative solutions to drive your business forward.',
      'description_2': 'Our team of experienced professionals leverages cutting-edge technologies and best practices to deliver measurable results, ensuring that your organization stays ahead of the competition. Whether you require assistance with high-performance system architecture, regulatory compliance, or cutting-edge development practices, our consulting services are designed to help you achieve your business goals efficiently and effectively.'
    },
    'solutions-intro': {
      'title_1': 'Scale across ',
      'title_2': 'Build new apps',
      'description': 'Your success shapes our collective future, as we pioneer a better world for all.',
      'solution-item_1': {
        'title': 'Architectural Assessment',
        'description': 'Conducting comprehensive assessments of existing IT architectures, identifying strengths, weaknesses, and areas for improvement. Developing strategic architectural roadmaps aligned with business objectives.'
      },
      'solution-item_2': {
        'title': 'System Design and Implementation',
        'description': 'Designing high-performance, scalable, and reliable systems tailored to meet specific business requirements. Implementing robust architectural solutions using cutting-edge technologies and best practices.'
      },
      'solution-item_3': {
        'title': 'DevOps Implementation',
        'description': 'Helping organizations adopt DevOps practices to streamline software development, deployment, and operations. Implementing continuous integration/continuous delivery (CI/CD) pipelines, infrastructure as code (IaC), and automated testing.'
      },
      'solution-item_4': {
        'title': 'Strategic Technology Consulting',
        'description': 'Providing strategic guidance on technology adoption, innovation, and digital transformation initiatives. Assisting businesses in leveraging emerging technologies such as AI, IoT, blockchain, etc., to gain a competitive edge.'
      },
      'solution-item_5': {
        'title': 'Customized Solution Development',
        'description': 'Developing custom software solutions tailored to address specific business challenges or opportunities. Leveraging agile methodologies to deliver high-quality, value-driven solutions on time and within budget.'
      },
      'solution-item_6': {
        'title': 'Technical Training and Workshops',
        'description': 'Offering training programs and workshops to educate teams on modern development methodologies, architectural best practices, and emerging technologies. Empowering teams to enhance their skills and adapt to evolving industry trends.'
      },
      'solution-item_7': {
        'title': 'Performance Optimization and Monitoring',
        'description': 'Optimizing system performance, scalability, and reliability through performance tuning, load testing, and monitoring. Implementing proactive monitoring solutions to identify and address potential issues before they impact operations.'
      },
      'solution-item_8': {
        'title': 'Regulatory Compliance and Governance',
        'description': 'Providing expertise in regulatory compliance frameworks such as ISO 27001, GDPR, etc. Developing governance strategies and implementing security controls to ensure compliance with industry regulations and standards.'
      },
      'solution-item_9': {
        'title': 'Stakeholder Engagement and Collaboration',
        'description': 'Facilitating communication and collaboration between business stakeholders and technical teams. Acting as a bridge to ensure alignment between business objectives and technical solutions.'
      },
      'solution-item_10': {
        'title': 'Cloud Migration',
        'description': 'Assisting businesses in migrating their infrastructure and applications to cloud platforms such as AWS, Azure, or Google Cloud. Optimizing cloud architectures for improved performance, cost-efficiency, and scalability.'
      }
    },
    'integrates': {
      'title_1': 'Integrates with your',
      'title_2': 'Workflow',
      'description': 'We offers seamless integrations with major, paid, and open-source systems to enhance your business operations. Whether it\'s connecting your cloud services, streamlining your development processes, or optimizing your front-end stack, we provide tailored solutions to meet your integration needs efficiently. From CI, to alerting, to reporting.'
    },
    'get-started': {
      'title_1': 'Let\'s create next big ',
      'title_2': 'thing together',
      'description': 'Leveraged expertise to support companies in various countries worldwide, delivering impactful solutions tailored to diverse business environments.'
    }
  },
  'our-values': {
    'title': 'Our Values',
    'description': 'Our values are the cornerstone of everything we do. They guide our actions, shape our culture, and drive our commitment to excellence.',
    'item_1': {
      'title': 'Innovation',
      'description': 'We embrace creativity, curiosity, and continuous learning to stay ahead of industry trends and deliver innovative solutions that meet the evolving needs of our clients.'
    },
    'item_2': {
      'title': 'Integrity',
      'description': 'We conduct ourselves with honesty, transparency, and accountability in all our interactions, fostering trust and credibility with our clients and partners.'
    },
    'item_3': {
      'title': 'Collaboration',
      'description': 'We believe in the power of teamwork and collaboration, working closely with our clients, partners, and internal teams to achieve shared goals and mutual success.'
    },
    'item_4': {
      'title': 'Excellence',
      'description': 'We are dedicated to delivering exceptional results and exceeding expectations in every project we undertake. We strive for excellence in everything we do, from the quality of our work to the level of service we provide.'
    },
    'item_5': {
      'title': 'Respect',
      'description': 'We value diversity, inclusion, and mutual respect, treating everyone with dignity, fairness, and kindness, regardless of background or perspective.'
    },
    'item_6': {
      'title': 'Customer Focus',
      'description': 'We prioritize our clients\' needs and objectives, taking the time to understand their challenges and goals and delivering tailored solutions that address their specific requirements.'
    },
    'services': {
      'title': 'Expert Consulting Services',
      'description': 'While competitors make promises, we deliver: next-gen technologies, reliable solutions, advanced architecture, and well-thought-out designs. These are not just words — they are our foundation. By choosing us, you choose confidence in complex systems!',
      'item_1': {
        'title': 'Big Picture Design',
        'description': 'We provide high-level architectural design solutions to help you visualize the overarching structure of your IT ecosystem.'
      },
      'item_2': {
        'title': 'Q&A Sessions',
        'description': 'Engage in interactive sessions with our experts and stakeholders to address queries and align project goals.'
      },
      'item_3': {
        'title': 'Feature Decomposition',
        'description': 'Our services include breaking down features into manageable components with clear integration points.'
      },
      'item_4': {
        'title': 'Scope Limitations',
        'description': 'We establish assumptions to define the project scope effectively and ensure realistic deliverables.'
      },
      'item_5': {
        'title': 'Work Estimations',
        'description': 'Benefit from our expertise in creating accurate work breakdown structures to estimate project efforts.'
      },
      'item_6': {
        'title': 'Delivery Timeline',
        'description': 'We outline delivery phases and milestones to keep projects on track and ensure timely completion.'
      },
      'item_7': {
        'title': 'Architecture Descriptions',
        'description': 'Receive comprehensive documentation detailing the scope, solution approach, and contribution to the delivery approach for your project.'
      }
    }
  },
  'solutions': {
    'title': 'Solutions',
    'description': 'Discover tailored strategies to enhance efficiency and drive innovation. From cloud migration to security architecture, unlock your business\'s potential with our expert solutions.',
    'solution-item_1': {
      'title': 'Cloud Migration Strategy',
      'description': 'Develop comprehensive strategies for migrating existing infrastructure and applications to cloud platforms like AWS, Azure, or Google Cloud, ensuring scalability, reliability, and cost-efficiency.'
    },
    'solution-item_2': {
      'title': 'Microservices Architecture',
      'description': 'Design and implement microservices-based architectures to break down monolithic applications into smaller, independently deployable services, enhancing agility, scalability, and maintainability.'
    },
    'solution-item_3': {
      'title': 'DevOps Implementation',
      'description': 'Facilitate the adoption of DevOps practices and tools to automate software development, testing, and deployment processes, enabling faster delivery cycles, higher quality, and improved collaboration between development and operations teams.'
    },
    'solution-item_4': {
      'title': 'Data Analytics Solutions',
      'description': 'Architect data analytics platforms and solutions leveraging big data technologies such as Hadoop, Spark, and Elasticsearch to derive actionable insights from large volumes of structured and unstructured data, enabling data-driven decision-making.'
    },
    'solution-item_5': {
      'title': 'API Strategy and Management',
      'description': 'Define API strategies and implement API management platforms to facilitate seamless integration between applications, partners, and third-party services, promoting interoperability, scalability, and security.'
    },
    'solution-item_6': {
      'title': 'Containerization and Orchestration',
      'description': 'Implement containerization technologies like Docker and container orchestration platforms such as Kubernetes to streamline application deployment, management, and scaling in dynamic environments.'
    },
    'solution-item_7': {
      'title': 'Security Architecture',
      'description': 'Develop robust security architectures and best practices to protect systems, applications, and data from evolving cyber threats, ensuring compliance with industry regulations and standards such as ISO 27001 and GDPR.'
    },
    'solution-item_8': {
      'title': 'Legacy Modernization',
      'description': 'Modernize legacy systems and applications by refactoring, rearchitecting, or replatforming them to leverage modern technologies and architectures, improving agility, performance, and scalability.'
    },
    'solution-item_9': {
      'title': 'Event-Driven Architectures',
      'description': 'Design event-driven architectures using technologies like Apache Kafka and RabbitMQ to enable real-time processing, event-driven workflows, and asynchronous communication between services.'
    },
    'solution-item_10': {
      'title': 'Continuous Improvement Frameworks',
      'description': 'Establish continuous improvement frameworks like Lean or Agile methodologies to foster a culture of innovation, collaboration, and iterative development within organizations.'
    },
    'faq': {
      'item_1': {
        'title': 'How can Solutions Architecture consulting improve my business\'s efficiency and agility?',
        'description': 'Solutions Architecture consulting can improve efficiency and agility by providing tailored strategies to optimize your IT infrastructure, streamline processes, and adapt to changing business needs. By aligning technology with business goals and leveraging best practices, we can help you achieve faster time-to-market, reduce costs, and stay competitive in the market.'
      },
      'item_2': {
        'title': 'What are the key benefits of implementing cloud migration strategies?',
        'description': 'Implementing cloud migration strategies offers several key benefits, including scalability, flexibility, and cost-effectiveness. By migrating your infrastructure to the cloud, you can scale resources on-demand, access advanced services, and reduce the need for physical infrastructure. Additionally, cloud platforms offer enhanced security features, disaster recovery options, and global reach, enabling your business to operate more efficiently and effectively.'
      },
      'item_3': {
        'title': 'How does security architecture consulting protect my company\'s sensitive data?',
        'description': 'Security architecture consulting focuses on identifying and mitigating security risks to protect your company\'s sensitive data. This involves implementing robust security measures, such as encryption, access controls, and intrusion detection systems, to safeguard against cyber threats and unauthorized access. By conducting risk assessments, developing security policies, and implementing industry best practices, we can help ensure the confidentiality, integrity, and availability of your data.'
      },
      'item_4': {
        'title': 'Can Solutions Architecture consulting help optimize our software development processes?',
        'description': 'Yes, Solutions Architecture consulting can help optimize software development processes by introducing best practices, automation tools, and modern methodologies such as Agile and DevOps. By conducting process assessments, identifying bottlenecks, and implementing continuous integration and delivery pipelines, we can streamline development workflows, improve collaboration between teams, and accelerate time-to-market for software releases.'
      },
      'item_5': {
        'title': 'What kind of support and guidance do you provide throughout the implementation of these solutions?',
        'description': 'We provide comprehensive support and guidance throughout the implementation of our solutions, including initial assessment and planning, solution design and architecture, implementation and deployment, and ongoing support and optimization. Our team of experienced consultants works closely with your stakeholders to understand your business requirements, define project goals, and deliver tailored solutions that meet your needs. We also offer training, documentation, and knowledge transfer to ensure your team is equipped to maintain and evolve the implemented solutions over time.'
      }
    }
  },
  'contact-us': {
    'title': 'Contact Us',
    'description': 'Your success is our success and together we help our society to become a better place to live and work.',
    'personal-data': 'I confirm my consent to the processing of my personal data in accordance with the Privacy Policy',
    'get-in-touch': {
      'title': 'Get in Touch',
      'description': 'Fill up the form. Fields marked with an asterisk (*) are required.'
    },
    'form': {
      'title-form': 'Contact Information',
      'full-name_ph': 'Full Name*',
      'company-name_ph': 'Company Name*',
      'your-email_ph': 'Your Email*',
      'your-phone-number_ph': 'Your Phone Number*',
      'title-topic': 'Choose the subject of your request?',
      'topic': {
        'blog': 'Blog',
        'job': 'Contract',
        'solutions': 'Solutions',
        'other': 'Other'
      },
      'textarea-title': 'How can we help you?',
      'success': 'Thank you! Your submission has been received!',
      'error': 'Oops! Something went wrong while submitting the form. Please try again later, or contact by different channel.'
    },
    'faq': {
      'title_1': 'Customers ',
      'title_2': 'frequently ask',
      'question-left_1': {
        'title': 'What is Solutions Architecture consulting, and why do I need it?',
        'description': 'Solutions Architecture consulting involves designing and implementing strategic IT solutions tailored to meet specific business needs. You need it to drive innovation, optimize your IT infrastructure, and solve complex business problems effectively.'
      },
      'question-left_2': {
        'title': 'What industries does your Solutions Architecture consulting services cater to?',
        'description': 'Our Solutions Architecture consulting services cater to a wide range of industries, including technology, finance, healthcare, retail, and more. Our expertise spans diverse sectors, allowing us to provide tailored solutions to meet the unique needs of each industry.'
      },
      'question-left_3': {
        'title': 'Can you provide examples of successful projects you\'ve completed in the past?',
        'description': 'Certainly! We have completed numerous successful projects for leading companies in various industries. For example, we helped Swiss Re optimize their IT infrastructure, resulting in a 30% increase in efficiency and a significant cost savings.'
      },
      'question-left_4': {
        'title': 'What methodologies and frameworks do you follow in your Solutions Architecture consulting process?',
        'description': 'We follow industry-standard methodologies and frameworks such as TOGAF, Agile, and DevOps in our Solutions Architecture consulting process. These frameworks help us ensure a structured and systematic approach to solution development, leading to successful outcomes.'
      },
      'question-left_5': {
        'title': 'What is the typical duration and cost of engaging with your Solutions Architecture consulting services?',
        'description': 'The duration and cost of engaging with our Solutions Architecture consulting services vary depending on the scope and complexity of the project. We provide transparent pricing and work closely with our clients to develop customized solutions that meet their budget and timeline requirements.'
      },
      'question-left_6': {
        'title': 'What is cheaper or more profitable: hiring your Solutions Architecture consulting company or hiring in-house staff solutions architecture?',
        'description': 'From a long-term perspective, hiring our Solutions Architecture consulting company can be more cost-effective. While hiring in-house staff may seem cheaper initially, it often requires significant investment in salaries, benefits, training, and infrastructure. Additionally, our consulting company can provide fast and high-quality results for next 2-3 years, leveraging our expertise and experience, which may ultimately save you money compared to the costs associated with hiring and maintaining full-time staff.'
      },
      'question-right_1': {
        'title': 'Why are your services reliable for our industry, and how can we trust your ideas and proposals?',
        'description': 'Our services are reliable because we are experts in the technical field. We speak the language of technology fluently and are well-versed in industry best practices. From initial estimations to final technical solutions, we ensure that everything we deliver is tailored to meet the unique technical needs and expectations of your business. Our extensive experience and proven track record in delivering successful projects across various domains guarantee that you can trust our ideas and proposals to drive your success.'
      },
      'question-right_2': {
        'title': 'How can Solutions Architecture consulting help my business stay competitive in the market?',
        'description': 'Solutions Architecture consulting helps your business stay competitive by enabling you to leverage the latest technologies, streamline processes, and deliver superior products and services to your customers, giving you a competitive edge.'
      },
      'question-right_3': {
        'title': 'Why do we need architects, what’s the benefit?',
        'description': 'We help reduce costs on disparate developments and eliminate the need to integrate them manually. We also translate business requirements into clear technical specifications for developers, making it easier to create products that precisely meet user needs. And that’s just part of how we can be useful!'
      },
      'question-right_4': {
        'title': 'How do you ensure regulatory compliance and governance in your Solutions Architecture consulting approach?',
        'description': 'We prioritize regulatory compliance and governance in our approach by following industry best practices, staying updated on relevant regulations, and implementing robust frameworks and controls to ensure compliance at every stage of the project.'
      },
      'question-right_5': {
        'title': 'How do you ensure communication and collaboration between business and technical teams during the consulting process?',
        'description': 'Communication and collaboration are key pillars of our consulting process. We facilitate regular meetings, use collaborative tools, and foster a culture of open communication to ensure alignment between business and technical teams, enabling seamless collaboration and teamwork.'
      },
      'question-right_6': {
        'title': 'How can I get started with your Solutions Architecture consulting services?',
        'description': 'Getting started with our Solutions Architecture consulting services is easy. Simply reach out to our team to schedule an initial consultation. We\'ll discuss your business goals, assess your needs, and develop a tailored plan to help you achieve success.'
      },
      'question-right_7': {
        'title': 'Which countries do you serve?',
        'description': 'We work with clients worldwide. However, the specific countries we serve depend on various factors. Please contact us to discuss your requirements, and we\'ll determine the best course of action together.'
      }
    },
    'inner-section': {
      'title_1': 'Ready to',
      'title_2': 'Transform Your Business?',
      'description': 'Still unsure if Solutions Architecture consulting is right for you? Let us help you make the decision. Schedule a consultation with our experts today and take the first step towards transforming your business.'
    },
    'world': {
      'title': 'Countries We Serve',
      'not-supported': 'This country is currently not supported.'
    }
  },
  'prices': {
    'title': 'Pricing Plans Tailored to Your Needs',
    'description': 'Pick an appropriate plan according to your business requirements. Our very fluid pricing options ensure you get the best value from expert support and high quality resources.',
    'quick_contact_1': {
      'title': 'Guidance in Choosing',
      'description': 'Not sure which plan suits you best? Our experts are ready to help analyze your needs and recommend the optimal solution.',
    },
    'quick_contact_2': {
      'title': 'Customer Support',
      'description': 'Have questions or need assistance? Our dedicated support team is here to provide guidance and ensure seamless service.',
    },
    'quick_contact_3': {
      'title': 'Access to Materials',
      'description': 'Benefit from our pre-developed resources and industry insights to accelerate your projects with proven methodologies and best practices.',
    },
    'plans': {
      'title': 'Pricing Plans for',
      'title_HL': 'Architecture Consulting',
      'description': 'Select an appropriate plan according to your business requirements. We provide consulting services to fit your specific situation, whether it’s through candidate interviews or comprehensive enterprise solutions. Check out our pricing plans and order expert solutions architecture advice today!',
      'plan_1': {
        'title': 'Architect Interview',
        'custom_price': false,
        'currency_icon': 'USD',
        'from': '',
        'price': '200',
        'frequency': 'session',
        'features': `
          ~1.5 hours sessions,
          Interviews conducted by professional architects only,
          Adaptation of questions to the hiring company,
          Recording of video and audio interviews,
          Highlight strong and weak sides of the candidate,
          Brief summary for candidate evaluation
        `,
        'suitable_for': 'Companies seeking architecture experts and wishing to professionally evaluate candidates before hiring.'
      },
      'plan_2': {
        'title': 'Software Architect',
        'custom_price': true,
        'currency_icon': '',
        'from': '',
        'frequency': '',
        'price': 'Price upon request',
        'features': `
          Proof of Concept (POC) and Initial Analysis,
          Architecture Modeling and Design,
          Documentation and Finalization,
          [HL]Support customers
        `,
        'suitable_for': 'Developing new applications or modernizing existing ones while considering all technical requirements.'
      },
      'plan_3': {
        'title': 'Solutions Architect II',
        'custom_price': true,
        'currency_icon': '',
        'from': '',
        'frequency': '',
        'price': 'Price upon request',
        'features': `
          Proof of Concept (POC); Requirements Analysis and Business Processes,
          Architecture Design and Documentation,
          Deployment; CI/CD; and Testing Strategies,
          Business Transformation and System Modernization,
          [HL]Support customers
        `,
        'suitable_for': 'Large companies planning to transition from legacy solutions to modern architectures with minimal risks.'
      },
      'detailed': {
        'title': 'Available services',
        'plans': 'Architect Interview, Software Architect, Solutions Architect, Solutions Architect II, Business Partner',
        'lists': {
          '0': `
          Personalized Questions,
          Interviews conducted by professional architects only,
          Interview Recording,
          Providing Interview Materials,
          Brief Summary,
          Questions relevant to the candidate's experience,
          Response Analysis,
          Soft & Hard Skills Assessment,
          Recommendations
          `,
          '1': `
          Gathering and analyzing functional (FR) requirements,
          Analyzing the technical environment,
          C4 model diagrams; Sequence diagram; Deployment diagram; Network diagram,
          Developing documentation for integrations and APIs,
          Preparing structured documentation,
          [HL]Support customers
          `,
          '2': `
          Everything from Software Architect role,
          Gathering and analyzing non-functional (NFR) requirements,
          Identifying and documenting business flow for BPM diagram construction,
          Development and prioritization of Quality Attributes Tree, 
          Preparing a comprehensive description of system capabilities,
          Calculating SLA (Service Level Agreement), 
          Developing proposals for Release Policy,
          Planning and optimizing CI/CD processes,
          Preparing recommendations for testing,
          Data analysis for business transformation; resource planning,
          [HL]Support customers
          `,
          '3': `
          Everything from Solutions Architect role,
          Collection and preparation of RACI matrix,
          Legacy applications reorganization with phased transition,
          Splitting into isolated domains (DDD),
          Cost assessment and migration strategy definition,
          [HL]Support customers,
          and even more...
          `,
          '4': `
          Everything from Solutions Architect II role,
          Business Domain; System; and Application Organization,
          Standardization and Process Acceleration,
          Architecture Governance,
          Technical Radar Creation,
          Reference Architecture Development,
          Organizational Structure Reorganization,
          [HL]Support customers
          `
        }
      }
    }
  }
}

const ru = {
  'header': {
    'main': 'Главная',
    'our-values': 'Наши ценности',
    'solutions': 'Решения',
    'prices': 'Услуги'
  },
  'buttons': {
    'contact-now': 'Свяжитесь сейчас',
    'get-started': 'Начнем',
    'our-solutions': 'Наши решения',
    'prices': 'Услуги',
    'submit': 'Отправить'
  },
  'footer': {
    'menu_header': 'Навигация',
    'description': 'Помогаем вашему бизнесу расти благодаря новаторским и профессиональным подходам.'
  },
  'home': {
    'top-title-1': 'Усильте свой бизнес ',
    'top-title-2': 'с помощью консалтинга по архитектуре решений.',
    'top-description': 'Начните путь цифровой трансформации, чтобы раскрыть весь потенциал вашей ИТ-инфраструктуры и внедрить инновации, двигающие ваш бизнес вперед.',
    'main-focus': {
      'title': 'Инновации решений',
      'description': 'Наши консультационные услуги разработаны для решения трех критически важных областей, необходимых для достижения успеха в бизнесе.',
      'focus_1': {
        'title': 'Решения для высокой производительности',
        'link-text': 'Изучить системы',
        'description': 'Мы используем опыт в проектировании и внедрении высоконагруженных систем для оптимизации производительности и надежности. Предоставим решения для масштабируемости и повышения эффективности, обеспечивая бесперебойную работу при высоких нагрузках.'
      },
      'focus_2': {
        'title': 'Соблюдение регулирования и управление',
        'link-text': 'Изучить стратегии',
        'description': 'Предоставим комплексные консультации для навигации в сложных регуляторных рамках. Разработаем надежные рамки и стратегии для соответствия отраслевым стандартам и правительственным регулированиям, минимизируя риски и защищая конфиденциальные данные.'
      },
      'focus_3': {
        'title': 'Практики разработки нового поколения',
        'link-text': 'Узнать больше',
        'description': 'Поможем клиентам применить современные методологии и инструменты для ускорения жизненного цикла разработки программного обеспечения. Поможем с практиками Agile и DevOps для улучшения сотрудничества и эффективной доставки качественных решений.'
      }
    },
    'inner-block': {
      'title_1': 'Укрепление масштабных решений ',
      'title_2': 'Инновационная архитектура будущего.',
      'description': 'Ваш успех формирует наше общее будущее, так как мы открываем путь к лучшему миру для всех.'
    },
    'why-trust': {
      'title_1': 'Почему компании большие и ',
      'title_2': 'малые доверяют нам?',
      'why_1': {
        'title': 'ISO',
        'description': 'Соответствие требованиям безопасности ISO-27001 для предприятий'
      },
      'why_2': {
        'title': 'TOGAF',
        'description': 'Ведущие практики в области архитектурных фреймворков, установленные в индустрии'
      },
      'why_3': {
        'title': '99%',
        'description': 'Уровень успешности или эффективности'
      },
      'why_4': {
        'title': '1000+',
        'description': 'Шаблонов, созданных экспертами и сообществом'
      },
      'why_5': {
        'title': '13+',
        'description': 'Лет опыта в руководстве и управлении международными командами'
      },
      'why_6': {
        'title': 'Peer',
        'description': 'Опыт работы в различных технических средах'
      }
    },
    'services': {
      'title_1': 'Улучшите свой бизнес: ',
      'title_2': 'Ведущие консалтинговые услуги',
      'description_1': 'Наши экспертные консалтинговые услуги предлагают комплексные решения, адаптированные к уникальным потребностям бизнеса в области архитектуры решений. От проектирования и оптимизации архитектуры до соблюдения требований по регулированию и управлению, мы предоставляем стратегическое руководство и инновационные решения для развития вашего бизнеса.',
      'description_2': 'Наша команда опытных профессионалов использует передовые технологии и лучшие практики для достижения измеримых результатов, обеспечивая вашей организации преимущество среди конкурентов. В независимости от того, нуждаетесь ли вы в помощи с высокопроизводительной системной архитектурой, регулированием и управлением, или передовыми практиками разработки, наши консультационные услуги предназначены для того, чтобы помочь вам достичь ваших бизнес-целей эффективно и результативно.'
    },
    'solutions-intro': {
      'title_1': 'Расширьте границы ',
      'title_2': 'Создавайте новые приложения',
      'description': 'Ваш успех формирует наше общее будущее, так как  мы открываем путь к лучшему миру для всех.',
      'solution-item_1': {
        'title': 'Оценка архитектуры',
        'description': 'Проведение всесторонней оценки существующих IT-архитектур, выявление сильных и слабых сторон, а также областей для улучшения. Разработка стратегических планов архитектуры, соответствующих бизнес-целям.'
      },
      'solution-item_2': {
        'title': 'Проектирование и внедрение',
        'description': 'Проектирование высокопроизводительных, масштабируемых и надежных систем, адаптированных для удовлетворения конкретных бизнес-требований. Внедрение надежных архитектурных решений с использованием передовых технологий и лучших практик.'
      },
      'solution-item_3': {
        'title': 'Внедрение DevOps',
        'description': 'Помощь организациям в применении практик DevOps для оптимизации разработки, развертывания и эксплуатации программного обеспечения. Внедрение непрерывной интеграции/непрерывной поставки (CI/CD), инфраструктуры как кода (IaC) и автоматизированного тестирования.'
      },
      'solution-item_4': {
        'title': 'Стратегическое технологическое консультирование',
        'description': 'Предоставление стратегических рекомендаций по внедрению технологий, инновациям и цифровым трансформациям. Помощь бизнесу в использовании новейших технологий, таких как искусственный интеллект, интернет вещей, блокчейн и т. д., для получения конкурентного преимущества.'
      },
      'solution-item_5': {
        'title': 'Разработка индивидуальных решений',
        'description': 'Разработка индивидуальных программных решений, адаптированных для решения конкретных бизнес-задач или возможностей. Использование гибких методологий для предоставления качественных, ценностно-ориентированных решений в срок и в рамках бюджета.'
      },
      'solution-item_6': {
        'title': 'Техническое обучение и семинары',
        'description': 'Предоставление обучающих программ и семинаров для обучения команд современным методологиям разработки, лучшим практикам в области архитектуры и новейшим технологиям. Помощь командам в повышении их квалификации и адаптации к изменяющимся трендам отрасли.'
      },
      'solution-item_7': {
        'title': 'Оптимизация производительности и мониторинг',
        'description': 'Оптимизация производительности, масштабируемости и надежности систем путем настройки производительности, нагрузочного тестирования и мониторинга. Помощь с проактивными мониторинговыми решениями для выявления и устранения потенциальных проблем до их влияния на систему.'
      },
      'solution-item_8': {
        'title': 'Соблюдение регулирования и управление',
        'description': 'Предоставление экспертной поддержки в области регулирования, таких как ISO 27001, GDPR и т. д. Разработка стратегий управления и внедрение защитных мер для обеспечения соблюдения отраслевых регулирований и стандартов.'
      },
      'solution-item_9': {
        'title': 'Вовлечение заинтересованных сторон и сотрудничество',
        'description': 'Обеспечение коммуникации и сотрудничества между бизнес-заинтересованными сторонами и техническими командами. Действуя как мост, гарантировать соответствие между бизнес-целями и техническими решениями.'
      },
      'solution-item_10': {
        'title': 'Миграция в облако',
        'description': 'Помощь бизнесу в миграции инфраструктуры и приложений на облачные платформы, такие как AWS, Azure или Google Cloud. Оптимизация облачных архитектур для повышения производительности, экономической эффективности и масштабируемости.'
      }
    },
    'integrates': {
      'title_1': 'Интегрируемся с вашим',
      'title_2': 'Рабочим процессом',
      'description': 'Мы предлагаем беспроблемные интеграции с основными, платными и открытыми системами для улучшения ваших бизнес-операций. Включая подключение ваших облачных сервисов, оптимизация процессов разработки или оптимизация стека фронтенда, мы предоставляем индивидуальные решения, чтобы удовлетворить ваши потребности в эффективной интеграции. От непрерывной интеграции до оповещения и отчетности.'
    },
    'get-started': {
      'title_1': 'Давайте создадим следующий',
      'title_2': 'большой проект вместе',
      'description': 'Используя наши знания, мы поддерживаем компании в разных странах мира, предоставляя влиятельные решения, адаптированные к различным бизнес-средам.'
    }
  },
  'our-values': {
    'title': 'Наши Ценности',
    'description': 'Наши ценности являются основой всего, что мы делаем. Они направляют наши действия, формируют нашу культуру и поддерживают наше стремление к совершенству.',
    'item_1': {
      'title': 'Инновации',
      'description': 'Мы приветствуем креативность, любопытство и постоянное обучение, чтобы быть впереди индустриальных тенденций и предлагать инновационные решения, отвечающие изменяющимся потребностям наших клиентов.'
    },
    'item_2': {
      'title': 'Честность',
      'description': 'Мы ведем себя с честностью, прозрачностью и ответственностью во всех наших взаимодействиях, способствуя доверию и авторитету перед нашими клиентами и партнерами.'
    },
    'item_3': {
      'title': 'Команда',
      'description': 'Мы верим в силу командной работы и сотрудничества, тесно сотрудничая с нашими клиентами, партнерами и внутренними командами для достижения общих целей и взаимного успеха.'
    },
    'item_4': {
      'title': 'Превосходство',
      'description': 'Мы стремимся к достижению исключительных результатов и превосходим ожидания в каждом проекте, который мы выполняем. Мы стремимся к превосходству во всем, начиная от качества нашей работы и заканчивая уровнем сервиса, который мы предоставляем.'
    },
    'item_5': {
      'title': 'Уважение',
      'description': 'Мы ценим разнообразие, включение и взаимное уважение, относясь к каждому с достоинством, справедливостью и добротой, независимо от его происхождения или точки зрения.'
    },
    'item_6': {
      'title': 'Ориентация на клиента',
      'description': 'Мы отдаем приоритет потребностям и целям наших клиентов, тщательно изучая их проблемы и цели, и предоставляя настраиваемые решения, отвечающие их конкретным требованиям.'
    },
    'services': {
      'title': 'Услуги консалтинга',
      'description': 'В то время как конкуренты обещают, мы воплощаем: прогрессивные технологии, стабильные решения, передовую архитектуру и продуманные дизайны. Это не просто слова — это наш фундамент. Выбирая нас, вы выбираете уверенность в сложных системах!',
      'item_1': {
        'title': 'Общая концепция дизайна',
        'description': 'Мы предоставляем решения высокоуровневого архитектурного дизайна, чтобы помочь вам визуализировать общую структуру вашей ИТ-экосистемы.'
      },
      'item_2': {
        'title': 'Сессии вопросов и ответов',
        'description': 'Участвуйте в интерактивных сессиях с нашими экспертами и заинтересованными сторонами, чтобы решать вопросы и выстраивать цели проекта.'
      },
      'item_3': {
        'title': 'Декомпозиция функционала',
        'description': 'Наши услуги включают разбиение функций на управляемые компоненты с четкими точками интеграции.'
      },
      'item_4': {
        'title': 'Ограничение объема работ',
        'description': 'Мы устанавливаем предположения для эффективного определения объема проекта и обеспечения реалистичности результатов.'
      },
      'item_5': {
        'title': 'Оценка объема работ',
        'description': 'Воспользуйтесь нашим опытом в создании точных структур разбиения работ для оценки усилий по проекту.'
      },
      'item_6': {
        'title': 'График поставки',
        'description': 'Мы определяем фазы поставки и вехи, чтобы сохранить проект на треке и обеспечить своевременное завершение.'
      },
      'item_7': {
        'title': 'Описания архитектуры',
        'description': 'Получите подробную документацию, описывающую объем, подход к решению и вклад в методику поставки для вашего проекта.'
      }
    }
  },
  'solutions': {
    'title': 'Решения',
    'description': 'Откройте для себя настроенные стратегии для повышения эффективности и стимулирования инноваций. От миграции в облако до архитектуры безопасности, разблокируйте потенциал вашего бизнеса с нашими экспертными решениями.',
    'solution-item_1': {
      'title': 'Стратегия миграции в облако',
      'description': 'Разработка всесторонних стратегий для миграции существующей инфраструктуры и приложений на платформы облака, такие как AWS, Azure или Google Cloud, обеспечивая масштабируемость, надежность и экономичность.'
    },
    'solution-item_2': {
      'title': 'Архитектура микросервисов',
      'description': 'Проектирование и внедрение архитектур на основе микросервисов для разбиения монолитных приложений на более мелкие, независимо развертываемые сервисы, повышая гибкость, масштабируемость и поддерживаемость.'
    },
    'solution-item_3': {
      'title': 'Внедрение DevOps',
      'description': 'Облегчите внедрение практик и инструментов DevOps для автоматизации процессов разработки, тестирования и развертывания программного обеспечения, обеспечивая более быстрые циклы поставки, более высокое качество и улучшенное взаимодействие между командами разработки и эксплуатации.'
    },
    'solution-item_4': {
      'title': 'Решения аналитики данных',
      'description': 'Архитектура аналитических платформ и решений с использованием технологий больших данных, таких как Hadoop, Spark и Elasticsearch, для получения практических выводов из больших объемов структурированных и неструктурированных данных, обеспечивая принятие решений на основе данных.'
    },
    'solution-item_5': {
      'title': 'Стратегия и управление API',
      'description': 'Определение стратегий API и реализация платформ управления API для обеспечения безпроблемной интеграции между приложениями, партнерами и сторонними сервисами, содействуя совместимости, масштабируемости и безопасности.'
    },
    'solution-item_6': {
      'title': 'Контейнеризация и оркестрация',
      'description': 'Внедрение технологий контейнеризации, таких как Docker, и платформ оркестрации контейнеров, таких как Kubernetes, для оптимизации развертывания, управления и масштабирования приложений в динамических средах.'
    },
    'solution-item_7': {
      'title': 'Архитектура безопасности',
      'description': 'Разработка надежных архитектур безопасности и передовых практик для защиты систем, приложений и данных от изменяющихся киберугроз, обеспечивая соответствие отраслевым стандартам и регулированиям, таким как ISO 27001 и GDPR.'
    },
    'solution-item_8': {
      'title': 'Модернизация устаревших систем',
      'description': 'Модернизация устаревших систем и приложений путем рефакторинга, реархитектуризации или переноса их на современные технологии и архитектуры, улучшая гибкость, производительность и масштабируемость.'
    },
    'solution-item_9': {
      'title': 'Архитектуры событийного управления',
      'description': 'Проектирование событийных архитектур с использованием технологий, таких как Apache Kafka и RabbitMQ, для обеспечения обработки в реальном времени, событийных рабочих процессов и асинхронного взаимодействия между службами.'
    },
    'solution-item_10': {
      'title': 'Неприрывные улучшения',
      'description': 'Установление фреймворков для непрерывного совершенствования, таких как методологии Lean или Agile, для развития культуры инноваций, сотрудничества и итеративной разработки в организациях.'
    },
    'faq': {
      'item_1': {
        'title': 'Как консультирование по архитектуре решений может повысить эффективность и гибкость моего бизнеса?',
        'description': 'Консультирование по архитектуре решений может повысить эффективность и гибкость, предоставляя настроенные стратегии для оптимизации вашей ИТ-инфраструктуры, упрощения процессов и адаптации к изменяющимся потребностям бизнеса. Выстраивая технологии в соответствии с бизнес-целями и используя передовые практики, мы поможем вам добиться более быстрого выхода на рынок, снизить затраты и оставаться конкурентоспособными на рынке.'
      },
      'item_2': {
        'title': 'Какие основные преимущества реализации стратегий миграции в облако?',
        'description': 'Реализация стратегий миграции в облако предлагает несколько ключевых преимуществ, включая масштабируемость, гибкость и экономичность. Миграция вашей инфраструктуры в облако позволяет масштабировать ресурсы по мере необходимости, получать доступ к расширенным сервисам и снижать необходимость в физической инфраструктуре. Кроме того, облачные платформы предлагают расширенные функции безопасности, варианты аварийного восстановления и глобальную доступность, обеспечивая более эффективное и эффективное функционирование вашего бизнеса.'
      },
      'item_3': {
        'title': 'Как консультирование по архитектуре безопасности защищает чувствительные данные моей компании?',
        'description': 'Консультирование по архитектуре безопасности фокусируется на выявлении и уменьшении рисков безопасности для защиты чувствительных данных вашей компании. Это включает в себя внедрение надежных мер безопасности, таких как шифрование, контроль доступа и системы обнаружения вторжений, для защиты от киберугроз и несанкционированного доступа. Проводя оценку рисков, разрабатывая политику безопасности и внедряя передовые практики отрасли, мы можем помочь обеспечить конфиденциальность, целостность и доступность ваших данных.'
      },
      'item_4': {
        'title': 'Может ли консультирование по архитектуре решений помочь оптимизировать наши процессы разработки программного обеспечения?',
        'description': 'Да, консультирование по архитектуре решений может помочь оптимизировать процессы разработки программного обеспечения путем внедрения передовых практик, инструментов автоматизации и современных методологий, таких как Agile и DevOps. Проводя оценку процессов, выявляя узкие места и внедряя непрерывные процессы интеграции и поставки, мы можем упростить рабочие процессы, улучшить взаимодействие между командами и ускорить время вывода программных продуктов на рынок.'
      },
      'item_5': {
        'title': 'Какую поддержку и руководство вы предоставляете в ходе реализации этих решений?',
        'description': 'Мы предоставляем всестороннюю поддержку и руководство на всех этапах реализации наших решений, включая начальную оценку и планирование, проектирование и архитектуру решения, внедрение и развертывание, а также постоянную поддержку и оптимизацию. Наша команда опытных консультантов тесно сотрудничает с вашими заинтересованными сторонами, чтобы понять ваши бизнес-потребности, определить цели проекта и предоставить настраиваемые решения, отвечающие вашим потребностям. Мы также предлагаем обучение, документацию и передачу знаний, чтобы ваша команда была готова поддерживать и развивать внедренные решения со временем.'
      }
    }
  },
  'contact-us': {
    'title': 'Свяжитесь с нами',
    'description': 'Ваш успех - наш успех! Вместе мы помогаем нашему обществу стать лучшим местом для жизни и работы.',
    'personal-data': 'Я подтверждаю своё согласие на обработку моих персональных данных в соответствии с Политикой конфиденциальности',
    'get-in-touch': {
      'title': 'Свяжитесь с нами',
      'description': 'Заполните форму. Поля, помеченные звездочкой (*), обязательны для заполнения.'
    },
    'form': {
      'title-form': 'Контактная информация',
      'full-name_ph': 'ФИО*',
      'company-name_ph': 'Название компании*',
      'your-email_ph': 'Ваш адрес электронной почты*',
      'your-phone-number_ph': 'Ваш номер телефона*',
      'title-topic': 'Выберите тему вашего запроса',
      'topic': {
        'blog': 'Блог',
        'job': 'Контракт',
        'solutions': 'Решения',
        'other': 'Другое'
      },
      'textarea-title': 'Как мы можем вам помочь?',
      'success': 'Спасибо! Ваше сообщение получено!',
      'error': 'Упс! Что-то пошло не так при отправке формы. Пожалуйста, попробуйте еще раз позже или свяжитесь с нами другим способом.'
    },
    'faq': {
      'title_1': 'Часто ',
      'title_2': 'задаваемые вопросы',
      'question-left_1': {
        'title': 'Что такое консалтинг в области архитектуры решений, и зачем мне это нужно?',
        'description': 'Консалтинг в области архитектуры решений включает в себя разработку и внедрение стратегических ИТ-решений, адаптированных под конкретные потребности бизнеса. Вам это нужно для того, чтобы продвигать инновации, оптимизировать ИТ-инфраструктуру и эффективно решать сложные бизнес-задачи.'
      },
      'question-left_2': {
        'title': 'Каким отраслям обслуживаются ваши услуги консалтинга в области архитектуры решений?',
        'description': 'Наши услуги консалтинга в области архитектуры решений охватывают широкий спектр отраслей, включая технологии, финансы, здравоохранение, розничную торговлю и другие. Наш опыт охватывает различные секторы, что позволяет нам предоставлять индивидуальные решения, отвечающие уникальным потребностям каждой отрасли.'
      },
      'question-left_3': {
        'title': 'Можете ли вы привести примеры успешных проектов, выполненных вами в прошлом?',
        'description': 'Конечно! Мы завершили множество успешных проектов для ведущих компаний различных отраслей. Например, мы помогли компании Swiss Re оптимизировать свою ИТ-инфраструктуру, что привело к увеличению эффективности на 30% и значительной экономии затрат.'
      },
      'question-left_4': {
        'title': 'Какие методологии и стандарты вы используете в процессе консалтинга в области архитектуры решений?',
        'description': 'Мы следуем стандартным методологиям и стандартам отрасли, таким как TOGAF, Agile и DevOps, в нашем процессе консалтинга в области архитектуры решений. Эти фреймворки помогают нам обеспечить структурированный и системный подход к разработке решений, что приводит к успешным результатам.'
      },
      'question-left_5': {
        'title': 'Каковы типичная продолжительность и стоимость взаимодействия с вашими услугами консалтинга в области архитектуры решений?',
        'description': 'Продолжительность и стоимость взаимодействия с нашими услугами консалтинга в области архитектуры решений зависят от объема и сложности проекта. Мы предоставляем прозрачную ценовую политику и тесно сотрудничаем с нашими клиентами для разработки индивидуальных решений, отвечающих их бюджету и срокам.'
      },
      'question-left_6': {
        'title': 'Что дешевле или более выгодно: нанять вашу компанию по консалтингу в области архитектуры решений или нанять внутренний штат архитекторов решений?',
        'description': 'С долгосрочной перспективы нанять нашу компанию по консалтингу в области архитектуры решений может быть более выгоднее. Хотя на первый взгляд найм внутреннего персонала может показаться дешевле, это часто требует значительных затрат на зарплаты, льготы, обучение и инфраструктуру. Кроме того, наша консалтинговая компания может обеспечить быстрые и качественные результаты на следующие 2-3 года, используя наши знания и опыт, что в конечном итоге может сэкономить вам деньги по сравнению с расходами, связанными с наймом и поддержкой штата сотрудников на полную ставку.'
      },
      'question-right_1': {
        'title': 'Почему ваши услуги надежны для нашей отрасли, и как мы можем доверять вашим идеям и предложениям?',
        'description': 'Наши услуги надежны, потому что мы являемся экспертами в технической сфере. Мы свободно говорим на языке технологий и хорошо знакомы с передовыми практиками. Начиная с первичных оценок и заканчивая готовыми техническими решениями, мы обеспечим, чтобы всё, что мы предоставляем, было адаптировано для удовлетворения уникальных технических потребностей и ожиданий вашего бизнеса. Наш обширный опыт и проверенная репутация в успешной реализации проектов в различных доменах гарантируют, что вы можете доверять нашим идеям и предложениям для достижения вашего успеха.'
      },
      'question-right_2': {
        'title': 'Как консалтинг в области архитектуры решений помогает моему бизнесу оставаться конкурентоспособным на рынке?',
        'description': 'Консалтинг в области архитектуры решений помогает вашему бизнесу оставаться конкурентоспособным, позволяя вам использовать последние технологии, оптимизировать процессы и предоставлять своим клиентам превосходные продукты и услуги, что дает вам конкурентное преимущество.'
      },
      'question-right_3': {
        'title': 'Зачем нам архитекторы, какой от них прок?',
        'description': 'Мы поможем снизить затраты на разрозненные разработки и избавим от необходимости самостоятельно их объединять. Также мы переведём бизнес-требования в понятные для разработчиков технические спецификации, что упростит создание продуктов, точно отвечающих потребностям пользователей. И это только часть того, чем мы можем быть полезны!'
      },
      'question-right_4': {
        'title': 'Как вы обеспечиваете соблюдение регулирующих требований и управление в вашем подходе к консалтингу в области архитектуры решений?',
        'description': 'Мы отдаем приоритет соблюдению регулирующих требований и управлению в нашем подходе, следуя лучшим отраслевым практикам, внедряя современные регулирующие рамки и контрольные механизмы для обеспечения соблюдения на всех этапах проекта.'
      },
      'question-right_5': {
        'title': 'Как вы обеспечиваете коммуникацию и сотрудничество между бизнес- и техническими командами в процессе консультирования?',
        'description': 'Коммуникация и сотрудничество являются ключевыми аспектами нашего процесса консультирования. Мы проводим регулярные совещания, используем совместные инструменты и способствуем развитию культуры открытого общения, чтобы обеспечить согласованность между бизнесом и техническими командами, обеспечивая безпрепятственное сотрудничество и командную работу.'
      },
      'question-right_6': {
        'title': 'Как я могу начать пользоваться вашими услугами консалтинга в области архитектуры решений?',
        'description': 'Начать пользоваться нашими услугами консалтинга в области архитектуры решений легко. Просто свяжитесь с нашей командой, чтобы назначить первую консультацию. Мы обсудим ваши бизнес-цели, оценим ваши потребности и разработаем индивидуальный план, чтобы помочь вам достичь успеха.'
      },
      'question-right_7': {
        'title': 'Какие страны вы обслуживаете?',
        'description': 'Мы работаем с клиентами по всему миру. Однако конкретные страны, которые мы обслуживаем, зависят от различных факторов. Пожалуйста, свяжитесь с нами, чтобы обсудить ваши потребности, и вместе мы определим наилучший путь действий.'
      }
    },
    'inner-section': {
      'title_1': 'Готовы',
      'title_2': 'Трансформировать свой бизнес?',
      'description': 'Все еще не уверены, подходит ли вам консультирование в области архитектуры решений? Позвольте нам помочь вам принять решение. Запишитесь на консультацию с нашими экспертами сегодня и сделайте первый шаг к трансформации вашего бизнеса.'
    },
    'world': {
      'title': 'Страны, которые мы обслуживаем',
      'not-supported': 'Эта страна на данный момент не поддерживается.'
    }
  },
  'prices': {
    'title': 'Гибкие тарифные планы для вашего бизнеса',
    'description': 'Выбирайте тарифный план, который соответствует вашим задачам. Мы предлагаем оптимальные решения с экспертной поддержкой и доступом к качественным ресурсам.',
    'quick_contact_1': {
      'title': 'Помощь в выборе',
      'description': 'Не знаете, какой тариф вам подходит? Наши эксперты помогут проанализировать ваши потребности и подобрать лучшее решение.',
    },
    'quick_contact_2': {
      'title': 'Поддержка клиентов',
      'description': 'Остались вопросы или нужна помощь? Наша команда поддержки всегда готова ответить и обеспечить бесперебойную работу.',
    },
    'quick_contact_3': {
      'title': 'Доступ к материалам',
      'description': 'Используйте наши наработки и аналитические материалы, чтобы ускорить реализацию проектов с проверенными методологиями и лучшими практиками.',
    },
    'plans': {
      'title': 'Тарифные планы для',
      'title_HL': 'Архитектурного консультирования',
      'description': 'Выберите подходящий план в соответствии с требованиями вашего бизнеса. Мы предоставляем консультационные услуги, адаптированные под вашу ситуацию – от интервью кандидатов до комплексных корпоративных решений. Ознакомьтесь с нашими тарифами и закажите экспертное архитектурное консультирование уже сегодня!',
      'plan_1': {
        'title': 'Интервью архитектора',
        'custom_price': false,
        'currency_icon': 'RUB',
        'from': '',
        'price': '20000',
        'frequency': 'сессия',
        'features': `
          ~1.5 часа сессии,
          Интервью проводятся только профессиональными архитекторами,
          Адаптация вопросов под нанимающую компанию,
          Запись видео и аудио интервью,
          Подчеркивание сильных и слабых сторон кандидата,
          Краткое резюме для оценки кандидата
        `,
        'suitable_for': 'Компании, ищущие экспертов в области архитектуры и желающие профессионально оценить кандидатов перед наймом.'
      },
      'plan_2': {
        'title': 'Архитектор ПО',
        'custom_price': true,
        'currency_icon': '',
        'from': '',
        'frequency': '',
        'price': 'Цена индивидуальная',
        'features': `
          Proof of Concept (POC),
          Первоначальный анализ,
          Моделирование и проектирование архитектуры,
          Документирование и финализация,
          [HL]Поддержка клиентов
        `,
        'suitable_for': 'Разработка новых приложений или модернизация существующих с учетом всех технических требований.'
      },
      'plan_3': {
        'title': 'Архитектор решений II',
        'custom_price': true,
        'currency_icon': '',
        'from': '',
        'frequency': '',
        'price': 'Цена индивидуальная',
        'features': `
          Proof of Concept (POC),
          Анализ требований и бизнес - процессов,
          Проектирование архитектуры и документирование,
          Развертывание; CI / CD; и стратегии тестирования,
          Трансформация бизнеса и модернизация систем,
          [HL]Поддержка клиентов
        `,
        'suitable_for': 'Крупные компании, планирующие переход от устаревших решений к современным архитектурам с минимальными рисками.'
      },
      'detailed': {
        'title': 'Доступные услуги',
        'plans': 'Интервью архитектора, Архитектор ПО, Архитектор решений, Архитектор решений II, Бизнес партнер',
        'lists': {
          '0': `
            Персонализированные вопросы,
            Интервью проводятся только профессиональными архитекторами,
            Запись интервью,
            Вопросы релевантные опыту кандидата,
            Предоставление материалов интервью,
            Краткий итог,
            Анализ ответов,
            Оценка Soft и Hard навыков,
            Рекомендации
          `,
          '1': `
            Сбор и анализ функциональных (FR) требований,
            Анализ технической среды,
            C4 model; Диаграмма последовательности; Диаграмма развертывания; Сетевая диаграмма,
            Разработка документации по интеграциям и API,
            Подготовка структурированной документации,
            [HL]Поддержка клиентов
          `,
          '2': `
            Все возможности роли Архитектора ПО,
            Сбор и анализ нефункциональных (NFR) требований,
            Идентификация и документирование бизнес-процессов для построения BPM-диаграммы,
            Разработка и приоритизация дерева атрибутов качества (Quality Attributes Tree), 
            Подготовка полного описания возможностей системы,
            Расчет SLA (Service Level Agreement), 
            Разработка предложений по Release Policy,
            Планирование и оптимизация процессов CI/CD,
            Подготовка рекомендаций по тестированию,
            Анализ данных для трансформации бизнеса; планирование ресурсов,
            [HL]Поддержка клиентов
          `,
          '3': `
            Все возможности роли Архитектора решений,
            Сбор и подготовка RACI-матрицы,
            Рефакторинг и реорганизация legacy-приложений с поэтапным переходом,
            Разделение на изолированные домены (DDD),
            Оценка затрат и определение стратегии миграции,
            [HL]Поддержка клиентов,
            и многое другое...
          `,
          '4': `
            Все возможности роли Архитектора решений II,
            Организация бизнес-доменов; систем и приложений,
            Стандартизация и ускорение процессов,
            Управление архитектурой (Architecture Governance),
            Создание Technical Radar,
            Разработка Reference Architecture,
            Реорганизация структуры компании,
            [HL]Поддержка клиентов
          `
        }
      }
    }
  }
}

export {
  en,
  ru
};