import css from './Footer.module.scss';
import Container from '../../container/Container';
import classNames from 'classnames';
import H6title from '../../titles/H6title';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export default function Footer() {
  const {t} = useTranslation();

  return (
    <div className={css.footer}>
      <Container>
        <div className={css.footerWrapper}>
          <div>
            <a href={'/'}>
              <img loading={'lazy'} alt={'header-logo'} src={`/footer_logo_svg_2_${i18n.language}.svg`}/> {/*android-chrome-512x512.png*/}
            </a>
            <p className={classNames(css.p, css.footerBrandDescription)}>
              {t('footer.description')}
            </p>
          </div>

          <div className={css.wrapLinkColumns}>
            <div className={css.footerLinksWrapper}>
              <div className={css.footerTitle}>
                <H6title isWhite={true}>{t('footer.menu_header')}</H6title>
              </div>
              <div className={css.contactUsInfoWrapper}>
                <a href={'/'} className={classNames(css.a, css.footerContactLink)}>{t('header.main')}</a>
                <a href={'/#/our-values'} className={classNames(css.a, css.footerContactLink)}>{t('header.our-values')}</a>
                <a href={'/#/solutions'} className={classNames(css.a, css.footerContactLink)}>{t('header.solutions')}</a>
                <a href={'/#/prices'} className={classNames(css.a, css.footerContactLink)}>{t('header.prices')}</a>
              </div>
            </div>
          </div>

          <div className={css.wrapLinkColumns}>
            <div className={css.footerLinksWrapper}>
              <div className={css.footerTitle}>
                <H6title isWhite={true}><a href={'/#/contact-us'}>{t('buttons.contact-now')}</a></H6title>
              </div>
              <div className={css.contactUsInfoWrapper}>
                <div className={classNames(css.smallText, css.light)}>Email</div>
                <a href={'mailto:contact-us@glad-expert.com'}
                   className={classNames(css.a, css.footerContactLink)}>contact-us@glad-expert.com</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}