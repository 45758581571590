import css from './HLList.module.scss';
import HLItem from './HLItem';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

export default function HLList() {
  const {t} = useTranslation();

  return (
    <>
      <div className={classNames(css.mt40, css.mb40, css.pricingPlansWrapper)}>
        <HLItem isCustom={t('prices.plans.plan_1.custom_price')}
                currencyChar={t('prices.plans.plan_1.currency_icon')}
                title={t('prices.plans.plan_1.title')}
                from={t('prices.plans.plan_1.from')}
                price={t('prices.plans.plan_1.price')}
                frequency={t('prices.plans.plan_1.frequency')}
                features={t('prices.plans.plan_1.features').split(',')}/>
        <HLItem isCustom={t('prices.plans.plan_2.custom_price')}
                currencyChar={t('prices.plans.plan_2.currency_icon')}
                title={t('prices.plans.plan_2.title')}
                from={t('prices.plans.plan_2.from')}
                price={t('prices.plans.plan_2.price')}
                frequency={t('prices.plans.plan_2.frequency')}
                features={t('prices.plans.plan_2.features').split(',')}/>
        <HLItem isCustom={t('prices.plans.plan_3.custom_price')}
                currencyChar={t('prices.plans.plan_3.currency_icon')}
                title={t('prices.plans.plan_3.title')}
                from={t('prices.plans.plan_3.from')}
                price={t('prices.plans.plan_3.price')}
                frequency={t('prices.plans.plan_3.frequency')}
                features={t('prices.plans.plan_3.features').split(',')} isHL={true}/>
      </div>
    </>
  );
}