import css from './MenuPlans.module.scss';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import DetailedItem from './DetailedItem';
import TextGradient from '../../titles/TextGradient';

export default function MenuPlans() {
  const {t} = useTranslation();
  const tabs = t('prices.plans.detailed.plans').split(',');

  const [selected, setSelected] = useState(0);

  const onSelect = (index) => {
    setSelected(index);
  };

  return (
    <>
      <div className={classNames(css.tabMenu, css.mb40)}>
        {
          tabs.map((tab, i) => (
            <a className={classNames(css.a, css.tabMenuItem, css.tabLink, {[css.current]: selected === i})}
               onClick={() => onSelect(i)}>
              {
                i === (tabs.length - 1) - 1
                  ? <TextGradient>{tab}</TextGradient>
                  : tab
              }
            </a>
          ))
        }
      </div>
      <div className={css.listDetailedItems}>
        {
          t(`prices.plans.detailed.lists.${selected}`).split(',').map((text, i) => (
            <DetailedItem number={i + 1} text={text}/>
          ))
        }
      </div>
    </>
  );
}