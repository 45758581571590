import css from './MainFocus.module.scss';
import classNames from 'classnames';
import H2title from '../../titles/H2title';
import Description from '../../description/Description';
import Container from '../../container/Container';
import Feature from './feature/Feature';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function MainFocus() {
  const {t} = useTranslation();
  const titleRef = useRef();
  const featuresRef = useRef();

  return (
    <div className={classNames(css.section, css.darkBgWithoutTopSpacing)}>
      <Container>
        <div ref={titleRef} className={css.sectionTitleWrapper}>
          <ShowAnimation ref={titleRef} order={1} direction={'v'}>
            <H2title isWhite={true}>{t('home.main-focus.title')}</H2title>
          </ShowAnimation>
          <ShowAnimation ref={titleRef} order={2} direction={'v'}>
            <Description>
              {t('home.main-focus.description')}
            </Description>
          </ShowAnimation>
        </div>
        <div ref={featuresRef} className={css.featuresWrapper}>
          <ShowAnimation ref={featuresRef} order={1} direction={'hl'}>
            <Feature image={'/focus_industries_1.svg'} title={t('home.main-focus.focus_1.title')}
                     linkText={t('home.main-focus.focus_1.link-text')} ind={1}
                     url={'/#/contact-us'}
                     description={t('home.main-focus.focus_1.description')}/>
          </ShowAnimation>
          <ShowAnimation ref={featuresRef} order={2} direction={'hl'}>
            <Feature image={'/focus_industries_2.svg'} title={t('home.main-focus.focus_2.title')}
                     linkText={t('home.main-focus.focus_2.link-text')} ind={2}
                     url={'/#/prices'}
                     description={t('home.main-focus.focus_2.description')}/>
          </ShowAnimation>
          <ShowAnimation ref={featuresRef} order={3} direction={'hl'}>
            <Feature image={'/focus_industries_3.webp'} title={t('home.main-focus.focus_3.title')}
                     linkText={t('home.main-focus.focus_3.link-text')} ind={3}
                     url={'/#/solutions'}
                     description={t('home.main-focus.focus_3.description')}
                     last={true}/>
          </ShowAnimation>
        </div>
      </Container>
    </div>
  );
}