import css from './ValueItem.module.scss';
import H5title from '../../../titles/H5title';
import classNames from 'classnames';
import TitleWrapper from '../../../titles/wrapper/TitleWrapper';

export default function ValueItem({title, description, url}) {
  return (
    <div className={css.valueBlockItem}>
      <div className={css.valuesIconWrapper}>
        <img alt={'value'} src={url} loading={'lazy'} className={classNames(css.img, css.valuesIcon)}/>
      </div>
      <TitleWrapper>
        <H5title isWhite={true} cn={css.mb5}>{title}</H5title>
      </TitleWrapper>
      <p className={classNames(css.p, css.white)} style={{textAlign: 'center'}}>{description}</p>
    </div>
  );
}