import css from './SimpleButton.module.scss';
import classNames from 'classnames';

export default function SubmitButton({isFullWidth, text, disabled, onClick}) {

  return (
    <input type={'button'}
           value={text}
           disabled={disabled}
           className={classNames(css.primaryButton, css.wButton, {[css.fullWidth]: isFullWidth})}
           onClick={onClick}/>
  );
}