import css from './DetailedItem.module.scss';
import classNames from 'classnames';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import H6title from '../../titles/H6title';

export default function DetailedItem({number, text}) {
  const hl = '[HL]';

  return (
    <>
      <div className={classNames(css.statisticItem, css.horizontal)}>
        <H2title cn={css.noOverflow}><TextGradient>{number}</TextGradient></H2title>
        <H6title>{text.indexOf(hl) > -1 ? <TextGradient>{text.replaceAll(hl, '')}</TextGradient> : text}</H6title>
      </div>
    </>
  );
}