import css from './QuickContactList.module.scss';
import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import classNames from 'classnames';
import ValueItem from '../banners/ourValuesList/valueItem/ValueItem';
import ShowAnimation from '../helpers/ShowAnimation';

export default function QuickContactList() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <>
      <section ref={ref} className={classNames(css.section, css.pricingServices)}>
        <ShowAnimation ref={ref} order={3} direction={'v'}>
          <div className={css.servicesWrapper}>
            <ValueItem url={'/values_passion.svg'} title={t('prices.quick_contact_1.title')}
                       description={t('prices.quick_contact_1.description')}/>
            <ValueItem url={'/values_teamwork.svg'} title={t('prices.quick_contact_2.title')}
                       description={t('prices.quick_contact_2.description')}/>
            <ValueItem url={'/values_innovation.svg'} title={t('prices.quick_contact_3.title')}
                       description={t('prices.quick_contact_3.description')}/>
          </div>
        </ShowAnimation>
      </section>
    </>
  );
}