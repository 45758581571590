import css from './SimpleButton.module.scss';
import classNames from 'classnames';

export default function SimpleButton({text, isSecondary, isFullWidth, url, onClick}) {
  return (
    <a className={classNames(css.primaryButton, css.wButton, {[css.secondaryButton]: isSecondary}, {[css.fullWidth]: isFullWidth})}
       href={url}
       onClick={onClick}>
      {text}
    </a>
  );
}